import { createContext, useContext, useState } from "react";

const UrlUploadContext = createContext<{
  urls: string[];
  addUrls: (urls: string[]) => void;
  removeUrls: (urls: string[]) => void;
  clearUrls: () => void;
} | null>(null);

export const UrlUploadProvider = ({ children }: { children: React.ReactNode }) => {
  const [urls, setUrls] = useState<string[]>([]);

  const addUrls = (newUrls: string[]) => {
    setUrls([...urls, ...newUrls]);
  };

  const removeUrls = (urlsToRemove: string[]) => {
    setUrls(urls.filter(url => !urlsToRemove.includes(url)));
  };

  const clearUrls = () => {
    setUrls([]);
  };

  return (
    <UrlUploadContext.Provider
      value={{
        urls,
        addUrls,
        removeUrls,
        clearUrls,
      }}
    >
      {children}
    </UrlUploadContext.Provider>
  );
};

export const useUrlUploadContext = () => {
  const context = useContext(UrlUploadContext);

  if (!context) {
    throw new Error("useUrlUploadContext must be used within a UrlUploadProvider");
  }

  return context;
};
